<template>
  <div class="appointmentManagement-view">
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="积分商城" name="first"
      ><integralMall v-if="activeName == 'first'" :jurisdiction="jurisdiction"
      /></el-tab-pane>
      <el-tab-pane label="积分使用记录" name="second"
      ><integraluseRecord
          v-if="activeName == 'second'"
          :jurisdiction="jurisdiction"
      ></integraluseRecord
      ></el-tab-pane>
      <el-tab-pane label="基本设置" name="third"
      ><basicSetup
          v-if="activeName == 'third'"
          :jurisdiction="jurisdiction"
      ></basicSetup
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import integralMall from "./integral/integralMall";
import integraluseRecord from "./integral/integraluseRecord";
import basicSetup from "./integral/basicSetup";
import { mapState } from 'vuex';

export default {
  name: "integralManagement",
  components: {
    integralMall,
    integraluseRecord,
    basicSetup
  },
  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("041")
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>



